import { Suspense, lazy } from "react"
import { createRoot } from 'react-dom/client'
import { LogoImage } from './components/icons/logo/LogoImage'
import { header } from './components/banners/Header'

const AppSlider = lazy(() => import('./AppSlider'))

const root = createRoot(document.getElementById('root'))
root.render( <Suspense delayMs={0} fallback={<div style={{display:'flex', width:'100%', height:'100vh', alignItems:'center', justifyContent:'center'}}><img src={LogoImage} width={'180px'} height={'123px'} alt='logo' fetchpriority="high" /></div>}>
                <AppSlider logoImage={LogoImage} header={header} />
            </Suspense> )